import React from 'react'

const Logo = () => (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 419.3 426.85">
  <g id="Layer_1" data-name="Layer 1">
    <path class="cls-1"
      d="M373,179.94a4,4,0,0,1-4.17-3.77c0-28.9,31.5-82.82,91.7-82.82a4,4,0,0,1,4.17,3.77c0,28.9-31.49,82.82-91.7,82.82"
      transform="translate(-45.37 -48.18)" />
    <path class="cls-1"
      d="M335.44,115.94c-49.79,0-75-21.53-75-64a4,4,0,0,1,4.17-3.76c49.79,0,75,21.53,75,64a4,4,0,0,1-4.17,3.77"
      transform="translate(-45.37 -48.18)" />
    <path class="cls-2"
      d="M352.12,252.11a4.41,4.41,0,0,1-1.18-.16,3.72,3.72,0,0,1-2.81-4.68c21-64.2-4.32-119.75-15.93-132.73a3.52,3.52,0,0,1,.62-5.29,4.47,4.47,0,0,1,5.86.56c14.49,16.18,39,73.81,17.44,139.6a4.14,4.14,0,0,1-4,2.7"
      transform="translate(-45.37 -48.18)" />
    <path class="cls-2"
      d="M360.39,195.22a4.71,4.71,0,0,1-1.33-.2,3.69,3.69,0,0,1-2.62-4.77c1.6-4.3,9.88-13.59,24.61-27.6,22.09-21,23.52-20.88,25.47-20.69a3.88,3.88,0,0,1,3.67,4.17,3.81,3.81,0,0,1-2.12,2.87c-4.47,3.21-41.09,36.56-43.73,43.65a4.16,4.16,0,0,1-3.95,2.57"
      transform="translate(-45.37 -48.18)" />
    <path class="cls-2"
      d="M335.44,115.94a4.38,4.38,0,0,1-3.08-1.22C319,101.54,297,87.77,296.73,87.63a3.55,3.55,0,0,1-1.07-5.24,4.46,4.46,0,0,1,5.8-1c.94.58,23.09,14.4,37.06,28.2a3.52,3.52,0,0,1-.27,5.32,4.35,4.35,0,0,1-2.81,1"
      transform="translate(-45.37 -48.18)" />
  </g>
  <g id="Layer_2" data-name="Layer 2">
    <path class="cls-2"
      d="M288.34,205.43V249.6q0,8.79-1.92,14.06a22.29,22.29,0,0,1-7,9.51,66.62,66.62,0,0,1-13.11,8.17,218.87,218.87,0,0,1-44.46,16.51A197.52,197.52,0,0,1,175.27,303q-29.34,0-53.49-8.17T80.62,271.13a104,104,0,0,1-26.14-37.72q-9.1-22.17-9.11-49.51,0-26.88,8.85-49.19A100.07,100.07,0,0,1,80.28,96.83Q97.47,81.27,122.13,73t55.92-8.25q25.71,0,45.51,6.21t32.13,15.63q12.31,9.44,18.58,20t6.25,18.71q0,8.81-7.21,15a26.52,26.52,0,0,1-28,3.85,24.09,24.09,0,0,1-8.6-6.6A187.89,187.89,0,0,0,220.6,117.1q-6.6-6.91-17.8-11.63t-28.57-4.71q-17.88,0-31.95,5.58a65.9,65.9,0,0,0-24.06,16.19,71,71,0,0,0-15.28,26,104.34,104.34,0,0,0-5.3,34.1q0,40.56,20.58,62.4t57.4,21.84a128.46,128.46,0,0,0,33.61-4.24,180.45,180.45,0,0,0,31.87-12.1V213.13H201.15q-14.42,0-21.79-3.93T172,195.84a15.76,15.76,0,0,1,6.17-12.73q6.17-5,16.76-5h58.53a82.29,82.29,0,0,1,18.23,1.73,20.71,20.71,0,0,1,12.07,7.7Q288.34,193.5,288.34,205.43Z"
      transform="translate(-45.37 -48.18)" />
    <path class="cls-2"
      d="M421.81,377.42v44.16q0,8.81-1.91,14.07a22.37,22.37,0,0,1-7,9.51,66.9,66.9,0,0,1-13.12,8.17,218.21,218.21,0,0,1-44.46,16.5A196.89,196.89,0,0,1,308.75,475q-29.36,0-53.49-8.17T214.1,443.12A104,104,0,0,1,188,405.4q-9.12-22.17-9.12-49.51,0-26.88,8.86-49.19a100,100,0,0,1,26.05-37.88q17.19-15.56,41.86-23.81t55.92-8.25q25.7,0,45.5,6.2t32.13,15.64q12.33,9.43,18.58,20T414,297.27q0,8.79-7.21,15a25.6,25.6,0,0,1-17.28,6.2,25.25,25.25,0,0,1-10.68-2.35,23.83,23.83,0,0,1-8.6-6.61,188.84,188.84,0,0,0-16.15-20.43q-6.6-6.91-17.8-11.63t-28.57-4.71q-17.9,0-32,5.58a65.78,65.78,0,0,0-24.05,16.19,71,71,0,0,0-15.29,26,104.35,104.35,0,0,0-5.29,34.1q0,40.56,20.58,62.4t57.4,21.84a128.44,128.44,0,0,0,33.6-4.24,180.45,180.45,0,0,0,31.87-12.1V385.12H334.63q-14.43,0-21.8-3.93t-7.38-13.36a15.75,15.75,0,0,1,6.16-12.73q6.17-5,16.76-5H386.9a82.38,82.38,0,0,1,18.24,1.73,20.74,20.74,0,0,1,12.07,7.7Q421.8,365.49,421.81,377.42Z"
      transform="translate(-45.37 -48.18)" />
  </g>
</svg>
)

export default Logo
